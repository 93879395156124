var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-embeddable-stores" }, [
    _vm._m(0),
    _c(
      "section",
      { staticClass: "section" },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : !_vm.hasEliteSubscription
          ? _c("AlertBoxSubscription")
          : !_vm.isLoading && !_vm.amplifyPlayer
          ? _c(
              "div",
              { staticClass: "container container--fullWidth h-textCenter" },
              [
                _c("h2", [_vm._v("You don't have a store created.")]),
                _c("p", [_vm._v("Get started today!")]),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--primary",
                    on: { click: _vm.saveAmplifyPlayer },
                  },
                  [_vm._v("Add Store")]
                ),
              ]
            )
          : _c(
              "div",
              {
                staticClass: "container container--fullWidth grid grid__embed",
              },
              [
                _c(
                  "iframe",
                  {
                    ref: "amplifyPlayerFrame",
                    attrs: {
                      src:
                        "https://embed.thecharts.com/" + this.amplifyPlayer.id,
                      width: "100%",
                      height: "450",
                    },
                  },
                  [_c("p", [_vm._v("Your browser does not support iframes.")])]
                ),
                _c("div", { staticClass: "wrapper--filled" }, [
                  _c("h2", { staticClass: "h2" }, [_vm._v("Customize player")]),
                  _c(
                    "div",
                    { staticClass: "colorPicker h-margin-20" },
                    [
                      _c("verte", {
                        attrs: {
                          model: "hex",
                          draggable: false,
                          showHistory: null,
                          enableAlpha: false,
                        },
                        model: {
                          value: _vm.amplifyPlayer.color_primary,
                          callback: function ($$v) {
                            _vm.$set(_vm.amplifyPlayer, "color_primary", $$v)
                          },
                          expression: "amplifyPlayer.color_primary",
                        },
                      }),
                      _c("div", [
                        _c("p", { staticClass: "colorPicker__textColor" }, [
                          _vm._v(_vm._s(_vm.amplifyPlayer.color_primary)),
                        ]),
                        _c(
                          "p",
                          { staticClass: "colorPicker__textDescription" },
                          [_vm._v("Primary color")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "p formLabel" }, [
                    _vm._v("Embed Code"),
                  ]),
                  _c(
                    "textarea",
                    {
                      staticClass:
                        "formInput formInput--fullWidth formInput--textarea",
                      attrs: { rows: "5" },
                    },
                    [_vm._v(_vm._s(_vm.embeddableCodeToShare) + " ")]
                  ),
                  _c("p", { staticClass: "p p--lightGrey textSizeXs" }, [
                    _vm._v(
                      "This code is used to embed the player onto your website."
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn--danger btn--border btn--sm",
                      on: { click: _vm.openDeleteConfirmActionModal },
                    },
                    [_vm._v("Delete")]
                  ),
                ]),
              ]
            ),
      ],
      1
    ),
    _c("div", { staticClass: "fabWrapper fabWrapper--right" }, [
      _c(
        "a",
        {
          staticClass: "btn btn--primary",
          on: { click: _vm.saveAmplifyPlayer },
        },
        [_vm._v("SAVE CHANGES")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h1", [_vm._v("Amplify Player")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }